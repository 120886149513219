main {
  padding-top: 63px;
  background: #f9fbfd;
  padding-left: 260px;
  transition: padding-left 0.4s;
  overflow-x: hidden;
}
.sidenav-toggled main {
  padding-left: 0px;
  transition: padding-left 0.4s;
}

.leftNavigationDrawer {
  min-width: 260px;
  padding-top: 20px;
  left: 0px;
  transition: left 0.4s;
  min-height: calc(100vh - 63px);
  position: fixed;
  z-index: 1;
  top: 63px;
  align-self: baseline;
}

.sidenav-toggled .leftNavigationDrawer {
  left: -260px;
  transition: left 0.4s;
}

@media (max-width: 968px) {
  main {
    padding-left: 0;
    transition: padding-left 0.4s;
  }
  .sidenav-toggled main {
    padding-left: 0;
    transition: padding-left 0.4s;
  }

  main div.container-lg {
    position: relative;
    left: 260px;
    transition: left 0.4s;
  }
  .sidenav-toggled main div.container-lg {
    left: 0px;
    transition: left 0.4s;
  }
}

table {
  width: 100%;
}

.pattern-square:after {
  background-image: url(https://block.codescandy.com/assets/images/pattern/pattern-square.svg);
  background-position: top;
  bottom: 0;
  content: "";
  height: 312px;
  left: 0;
  -webkit-mask-image: linear-gradient(0deg, transparent 35%, #000 75%);
  mask-image: linear-gradient(0deg, transparent 35%, #000 75%);
  padding: 40px 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.form-control {
  padding: 0.575rem 0.5rem;
  font-size: 15px;
}
.adjust-custom-padding {
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
}
.custom-btn-icon {
  width: 2rem !important;
  height: 2rem !important;
}

#processingModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1050; /* Ensure this is higher than Bootstrap's default modal z-index */
  display: flex;
  align-items: center;
  justify-content: center;
}

#processingModal .modal-dialog {
  max-width: 200px; /* Adjust width as needed */
}

#processingModal .modal-content {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

#processingModal .modal-body {
  background-color: rgba(0, 0, 0, 0.7); /* Adjust overlay opacity */
  padding: 20px;
  border-radius: 8px;
}

#processingModal .spinner-border {
  width: 3rem;
  height: 3rem;
}

#processingModal p {
  margin-top: 10px;
  color: white; /* Adjust text color */
}
