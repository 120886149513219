/* Main Template css */
.temptext {
    color: rgb(35, 0, 149);
    font-size: 23px;
    font-weight: 500;
  }
  .tempcard {
    width: 300px;
    height: 250px;
    margin-top: 20px;
    border: 1px solid black;
    border-radius: 1px solid black;
    /* background-image: url("/images/templateimg.png"); */
  }
  .bottomdiv {
    background-color: #141414;
    height: 60px;
    width: 100%;
    position: absolute;
    bottom: 0;
    border-bottom-right-radius: 11px;
    border-bottom-left-radius: 11px;
    opacity: 0.6;
  }
  .radioicon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  
  /* Template1 css */
  
  .main222 {
    display: flex;
    justify-content: center;
    /* min-height: 100vh; */
  }
  .text1 {
    color: black;
    font-size: 55px;
    font-weight: 550;
    font-family: "Libre Baskerville", serif;
    display: block;
  }
  @media (max-width: 1440px) {
    .text1 {
      font-size: 50px;
    }
  }
  @media (max-width: 800px) {
    .text1 {
      font-size: 30px;
    }
  }
  @media (max-width: 500px) {
    .text1 {
      font-size: 25px;
    }
  }
  
  .text2 {
    color: black;
    font-size: 15px;
    font-weight: 500;
    display: block;
  }
  @media (max-width: 380px) {
    .text2 {
      font-size: 13px;
      margin-top: -5px;
    }
  }
  .text3 {
    color: black;
    font-size: 15px;
    font-weight: 400;
  }
  .text4 {
    display: block;
    color: #454056;
    font-size: 15px;
    font-weight: 400;
    border: none;
  
    width: 300px;
  }
  .text4:focus {
    box-shadow: 0 0 0 0.1rem #dee2e6;
  }
  .borderfield {
    box-shadow: 0 0 0 0.1rem #dee2e6;
  }
  
  .textdate {
    display: block;
    font-size: 15px;
    font-weight: 400;
    border: none;
  }
  .textdate:focus {
    box-shadow: 0 0 0 0.1rem #dee2e6;
  }
  @media (max-width: 550px) {
    .text4 {
      width: 280px;
    }
  }
  .customDatePicker {
    width: 150px;
  }
  @media print {
    .hide {
      display: none;
    }
  }
  .sub {
    padding-left: 40px;
  }
  .text41 {
    display: block;
    color: black;
    font-size: 15px;
    font-weight: 500;
    border: none;
    box-shadow: none;
  
    width: 300px;
  }
  .text5 {
    display: block;
    color: black;
    font-size: 14px;
    font-weight: 500;
    /* width: 100%; */
  }
  .text6 {
    display: block;
    color: "black";
    font-size: 15px;
    font-weight: 700;
    padding-top: 10px;
    text-transform: uppercase;
    text-align: right;
  }
  .text7 {
    display: block;
    color: #1b4fe0;
    font-size: 12px;
    font-weight: 450;
    text-transform: uppercase;
    text-align: right;
  
    padding-top: 3px;
  }
  .text8 {
    display: block;
    color: black;
    font-size: 15px;
    font-weight: 500;
    text-align: right;
    border: none;
    box-shadow: none;
    /* margin-left: 20px; */
    background-color: white;
    color: black;
  }
  
  .text8editdatepicker {
    display: block;
    color: black;
    font-size: 13px;
    font-weight: 400;
    text-align: right;
    border: none;
    box-shadow: none;
    margin-left: 60px;
    background-color: white;
    color: black;
    padding-right: 0;
  }
  
  .text9 {
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .row {
    margin-left: 0px;
    margin-right: 0px;
  }
  .text10 {
    display: block;
    color: black;
    font-size: 15px;
    font-weight: 500;
    text-align: right;
  }
  .text111 {
    color: black;
    font-size: 15px;
    font-weight: 600;
    text-align: right;
    display: block;
    border: none;
  }
  .text33 {
    color: black;
    font-size: 15px;
    font-weight: 600;
    display: block;
  }
  .blur-background {
    filter: blur(5px);
    transition: filter 0.3s ease-in-out;
  }
  
  .datewrapper {
    width: auto !important;
  }
  .datewrapper-temp-3 {
    width: 50% !important;
  }
  .text11 {
    color: #454056;
    font-size: 15px;
    font-weight: 400;
    text-align: right;
    display: block;
    border: none;
  }
  .line {
    background-color: #7b6be3;
    height: 10px;
  }
  .selectmode {
    max-width: 220px;
    padding-left: 5px;
    margin-bottom: 10px;
  }
  
  .pmtmode {
    max-width: 220px;
    border: none;
    padding: 0;
  }
  .sizewt {
    color: #454056;
    font-size: 15px;
    font-weight: 400;
  }
  .gstdis {
    width: 40px;
    border: none;
    height: 20px;
    padding: 0px;
    /* margin-left: -5px; */
  }
  .form-check-label {
    color: #454056 !important;
  }
  .footer {
    position: absolute;
    bottom: 0;
    /* left: 0; */
    width: 100%;
    padding: 10px;
  }
  .total1 {
    color: white;
    font-size: 22px;
    font-weight: 600;
    text-align: end;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .tab2th {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: start;
  }
  .total3 {
    color: black;
    font-size: 22px;
    font-weight: 600;
    text-align: end;
    align-items: center;
  }
  .text12 {
    color: #454056;
    font-size: 15px;
    font-weight: 400;
    text-align: right;
    display: block;
  }
  .text13 {
    color: black;
    font-size: 17px;
    font-weight: 450;
    display: block;
    text-align: right;
  }
  .text14 {
    color: black;
    font-size: 40px;
    font-weight: 550;
    font-family: "Libre Baskerville", serif;
    display: block;
  }
  .text15 {
    display: block;
    color: black;
    font-size: 12px;
    font-weight: 600;
    padding-top: 5px;
    text-transform: uppercase;
    text-align: right;
  }
  .text16 {
    color: #454056;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .hrtag {
    border: 1px solid #d1d1d1;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 90px;
  }
  @media (max-width: 580px) {
    .hrtag {
      margin-top: 10px;
    }
  }
  @media (max-width: 501px) {
    .templete12topleftdiv {
      width: 100%;
    }
  }
  
  /* Template3 css */
  
  .text17 {
    color: black;
    font-size: 23px;
    font-weight: 400;
    display: block;
    text-align: left;
    text-transform: uppercase;
  }
  .text18 {
    color: black;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    display: block;
  }
  .text19 {
    color: #ffaa33;
    font-size: 25px;
    font-weight: 550;
    font-family: "Libre Baskerville", serif;
    display: block;
  }
  .text20 {
    display: block;
    color: black;
    font-size: 13.5px;
    font-weight: 400;
  }
  .text21 {
    display: block;
    color: #ffaa33;
    font-size: 13px;
    font-weight: 600;
    padding-top: 5px;
    text-transform: uppercase;
    text-align: right;
  }
  .text22 {
    font-size: 15px;
    font-weight: 300;
    font-family: "Inter", sans-serif;
    color: black;
  }
  .middatediv {
    width: 400px;
  }
  @media (max-width: 600px) {
    .middatediv {
      width: 300px;
    }
  }
  .bottomdiv {
    margin-top: 150px;
  }
  @media (max-width: 500px) {
    .bottomdiv {
      margin-top: 50px;
    }
  }
  /* .react-datepicker__input-container {
    width: 116%;
  } */
  .temp1row {
    background-color: white;
    --x-table-striped-bg: white;
    --x-table-hover-bg: white;
  }
  
  /* Templete 2 css */
  .temp2text1 {
    color: black;
    font-size: 40px;
    font-weight: 550;
    font-family: "Libre Baskerville", serif;
    display: block;
    /* margin-top: 64px; */
  }
  @media (max-width: 500px) {
    .temp2text1 {
      font-size: 25px;
    }
  }
  .temp2text4 {
    display: block;
    color: black;
  
    border: none;
    box-shadow: none;
    margin-bottom: -10px;
    width: 300px;
  }
  @media (max-width: 350px) {
    .temp2text4 {
      width: 280px;
    }
  }
  .temp2text6 {
    display: block;
    color: black;
    font-size: 12px;
    font-weight: 600;
    padding-top: 4px;
    text-transform: uppercase;
    text-align: right;
  }
  .temp2text7 {
    display: block;
    color: black;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: right;
  
    padding-top: 3px;
  }
  .temp2text9 {
    color: black;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: left;
  }
  
  /* Templete 31 css */
  .temp3text1 {
    color: #e8a317;
    font-size: 30px;
    font-weight: 500;
    font-family: "Libre Baskerville", serif;
    display: block;
    margin-top: -3px;
  }
  @media (max-width: 500px) {
    .temp3text1 {
      font-size: 25px;
    }
  }
  @media (max-width: 380px) {
    .temp3text1 {
      margin-left: 15px;
      font-size: 20px;
    }
  }
  .temp3text8 {
    display: block;
    color: black;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    border: none;
    box-shadow: none;
    padding: 0px;
  }
  
  .temp3text11 {
    color: black;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    display: block;
  }
  
  .temp23text8 {
    display: block;
    color: black;
    font-size: 13px;
    font-weight: 400;
    text-align: right;
    border: none;
    box-shadow: none;
  }
  .temp3total {
    background-color: #e8a317;
    width: 40%;
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  @media (max-width: 430px) {
    .temp3total {
      width: 55%;
    }
  }
  @media (max-width: 770px) {
    .templeteresponsive {
      display: flex;
      justify-content: center;
    }
  }
  .temp23totaltxt {
    color: black;
    font-size: 15px;
    font-weight: 500;
    margin-right: 0px;
    display: block;
    text-align: end;
    margin-right: 40px;
  }
  
  .Temp23totaltxt2 {
    color: black;
    font-size: 30px;
    font-weight: 500;
    margin-right: 0px;
    display: block;
    text-align: end;
    margin-right: 40px;
    align-items: center;
  }
  @media (max-width: 510px) {
    .Temp23totaltxt2 {
      font-size: 16px;
    }
  }
  .temp21totaltxt {
    color: black;
    font-size: 30px;
    font-weight: 500;
    margin-right: 0px;
    display: block;
    text-align: end;
    margin-right: 40px;
    align-items: center;
  }
  
  @media (max-width: 510px) {
    .temp21totaltxt {
      font-size: 16px;
    }
  }
  .Temp3edittotal {
    color: white;
    font-size: 17px;
    font-weight: 500;
  }
  @media (max-width: 510px) {
    .Temp3edittotal {
      font-size: 12px;
    }
  }
  .temp2edittotal {
    color: black;
    font-size: 30px;
    font-weight: 500;
    margin-right: 0px;
    display: block;
    text-align: end;
    margin-right: 40px;
    align-items: center;
  }
  @media (max-width: 460px) {
    .temp2edittotal {
      font-size: 16px;
      font-weight: 400;
    }
  }
  @media print {
    .fixedbottom {
      position: fixed;
      bottom: 0;
      width: 100%;
      padding: 0;
      text-align: center;
      margin-right: 4px;
    }
  }
  .bottomProperty {
    margin-top: 12rem !important;
  }
  .form-select {
    padding: 0.575rem 2.53125rem 0.575rem 0.5rem;
  }
  .applyClassminWidth-auto + div {
    min-width: auto;
  }
  .form-floating > label {
    padding: 0.3rem 1.1rem !important;
  }