.topnav {
  padding-left: 0;
  /* height: 4.2rem; */
  z-index: 1039;
  font-size: 0.9rem;
}

.topnav.custom-bg img.logoImage {
  height: 45px !important;
  transition: all linear 0.5s;
}
img.logoImage {
  height: 60px !important;
  transition: all linear 0.5s;
}

.topnav .navbar-brand {
  width: 100%;
  /* //width: 10rem; */
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
}
.topnav .navbar-brand img {
  height: 0.8rem;
}
@media (min-width: 992px) {
  .topnav .navbar-brand {
    width: 100%;
    /* width: 10rem; */
  }
}
.shadow {
  box-shadow: 0 0.15rem 1.75rem 0 rgb(33 40 50 / 15%) !important;
}
.navbar-expand {
  flex-wrap: nowrap;
  /* justify-content: flex-start; */
}
.navbar {
  position: fixed;
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  /* justify-content: space-between; */
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  /* background-color: rgb(0 0 0 / 65%) !important; */
  top: 0;
}

.nav-fixed .topnav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  background-color: #008e00;
}
.custom-bg {
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: rgb(0 0 0 / 65%) !important;
}

.react-datepicker-wrapper {
  width: 100%;
}
/* .react-datepicker__input-container {
  height: 100%;
} */
.activnavbar {
  background-color: rgba(241, 124, 58, 0.14);
  color: #fd5631ed;
  border-radius: 5px;
  margin: 8px;
}
.activnavbar:focus {
  outline: none; /* Remove default focus outline */
  color: #fd5631ed; /* Red color when focused (after clicking) */
}
.hovernavbar:hover {
  --fi-nav-link-hover-color: "none";
  color: #fd5631ed; /* Red color on hover */
  transition: color 0.3s;
}
.wrapper-custom-width {
  width: 86% !important;
}
.wrapper-custom-width-2 {
  width: 85.5% !important;
}
